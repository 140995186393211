<template>
  <v-menu
    v-if="date"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div id="textfield">
        <v-text-field
          v-model="date"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </div>
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      no-title
      scrollable
      @change="setMonth()"
    />
  </v-menu>
</template>

<script>
export default {
  name: "MonthSelect",

  props: {
    // YYYY-MM
    initValue: {
      type: String,
      required: true
    },
    label: {
      type: String
    }
  },

  data: () => ({
    date: null,
    menu: false,
    modal: false
  }),

  created() {
    this.date = this.initValue;
  },

  methods: {
    setMonth() {
      this.$refs.menu.save(this.date);
      this.menu = false;
      this.$emit("change", { month: this.date });
    }
  }
};
</script>

<style scoped>
#textfield {
  max-width: 130px;
}
</style>
