<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  name: "PieChart",

  props: {
    // See vue-chartjs.org
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },

  watch: {
    data: {
      immediate: false,
      handler() {
        // Must destory previous chart
        this.$data._chart.destroy();
        this.render();
      }
    }
  },

  mounted() {
    this.render();
  },

  methods: {
    render() {
      this.renderChart(this.data, this.options);
    },
  }
};
</script>
