<template>
  <div>
    <LoadingSpinner v-if="!generalInfo" class="pa-16" />
    <v-card v-else>
      <v-card-title>
        {{ generalInfo.VarslingNavn }}
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="activeTab" show-arrows>
          <v-tab :key="1" data-testid="overviewAlertDetails">{{
            $t("alertOverview")
          }}</v-tab>
          <v-tab :key="2" data-testid="receiversAlertDetails">{{
            $t("receivers")
          }}</v-tab>
          <v-tab :key="3" data-testid="responseAlertDetails">{{
            $t("responses")
          }}</v-tab>
          <v-tab-item :key="1">
            <LoadingSpinner v-if="!generalInfo" />
            <v-card v-else>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-spacer v-if="!$vuetify.breakpoint.xs" />
                    <UpdateBtn
                      v-on:click="update(true)"
                      class="mt-4 action-btn"
                      :block="$vuetify.breakpoint.xs"
                      data-testid="updateAlertDetails"
                    />
                  </v-row>
                  <v-row no-gutter>
                    <v-col cols="12" sm="12" md="6">
                      <AlertTimeline v-if="statusLog" :status-log="statusLog" />
                    </v-col>
                    <v-spacer />
                    <v-col cols="12" sm="12" md="6" class="justify-end">
                      <br />
                      <v-btn
                        v-if="alertCanBeStoped"
                        @click="cancelAlert"
                        class="mt-4 action-btn"
                        :block="$vuetify.breakpoint.xs"
                        outlined
                        color="red"
                      >
                        <v-icon left>
                          mdi-cancel
                        </v-icon>
                        {{ $t("cancelAlert") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <br />
                <AlertStatsPie
                  :alert-stats="stats"
                  :alert-sim-mode="generalInfo.Simulering"
                  :alert-status="generalInfo.VarslingStatus"
                />
                <br />
                <SimpleCard
                  v-if="generalInfo.Sms.Melding"
                  :title="$t('smsText')"
                  :text="generalInfo.Sms.Melding"
                />
                <br />
                <SimpleCard
                  v-if="generalInfo.Epost.Melding"
                  :title="$t('emailText')"
                  :text="generalInfo.Epost.Melding"
                />
                <br />
                <v-card v-if="attachmentMetaEmail?.length">
                  <v-card-title>
                    {{ $t("attachments") }}
                  </v-card-title>
                  <v-card-text>
                    <ul v-for="attachMeta in attachmentMetaEmail">
                      <li>
                        <i>{{ attachMeta.FileName }}</i>
                        <span v-if="attachMeta.Description">
                          - {{ attachMeta.Description }}
                        </span>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
                <br />
                <SimpleCard
                  v-if="generalInfo.Tale.Content.trim()"
                  :title="$t('voiceText')"
                  :text="generalInfo.Tale.Content"
                />
                <br />
                <v-card v-if="generalInfo.Tale.AudioFileId">
                  <v-card-title>
                    {{ $t("soundFile") }}
                  </v-card-title>
                  <v-card-text>
                    <div class="mb-4" v-if="attachmentMetaAudio">
                      <i>{{ attachmentMetaAudio.FileName }}</i>
                      <span v-if="attachmentMetaAudio.Description">
                        - {{ attachmentMetaAudio.Description }}
                      </span>
                    </div>
                    <AudioPlayback
                      :alert-audio-file-id="generalInfo.Tale.AudioFileId"
                    />
                  </v-card-text>
                </v-card>
                <div class="mt-6">
                  <p>
                    {{ $t("groupsUsed") }}:
                    <v-chip v-for="groupUsed in groupsUsed" small class="mr-2">
                      {{ groupUsed }}
                    </v-chip>
                  </p>
                  <p>{{ $t("alertRegBy") }}: {{ statusLog[0].BrukerNavn }}</p>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="2">
            <AlertReceiverList
              :alert-id="alertId"
              :alert-name="generalInfo.VarslingNavn"
            />
            <p v-if="generalInfo.TjenesteFilter" class="mt-4">
              {{ $t("numTypeAlerted") }}: {{ phoneTypeText }}
            </p>
          </v-tab-item>
          <v-tab-item :key="3">
            <AlertResponseOverview
              :alert-id="alertId"
              :alert-name="generalInfo.VarslingNavn"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions v-if="showCardActions">
        <v-spacer />
        <v-btn
          class="primary"
          @click="$emit('close')"
          data-testid="closeAlertDetails"
        >
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import LoadingSpinner from "../misc/LoadingSpinner";
import apiAlert from "../../api/v24/api.alert";
import AlertTimeline from "./AlertTimeline";
import SimpleCard from "../misc/SimpleCard";
import AlertStatsPie from "./AlertStatsPie";
import AlertReceiverList from "./AlertReceiversList";
import UpdateBtn from "../misc/UpdateBtn";
import store from "../../store";
import AlertResponseOverview from "@/components/alertLog/AlertResponseOverview";
import i18n from "@/i18n";
import apiFile from "@/api/v24/api.file";
import AudioPlayback from "@/components/files/AudioPlayback.vue";
import phoneNumType from "@/util/phoneNumType";
import AlertMeta from "@/util/AlertMeta";

export default {
  name: "AlertDetails",
  components: {
    AudioPlayback,
    AlertResponseOverview,
    UpdateBtn,
    AlertReceiverList,
    AlertStatsPie,
    SimpleCard,
    AlertTimeline,
    LoadingSpinner
  },

  props: {
    alertId: {
      required: true,
      type: String
    },
    showCardActions: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    alertCanBeStoped() {
      return (
        this.generalInfo &&
        (this.generalInfo.VarslingStatus === "P" ||
          this.generalInfo.VarslingStatus === "A")
      );
    },
    phoneTypeText() {
      return phoneNumType.getTypeText(this.generalInfo.TjenesteFilter);
    },
    groupsUsed() {
      const alertMeta = new AlertMeta();
      return alertMeta.getGroupsUsed(this.generalInfo.Kommentarer);
    }
  },

  data: () => ({
    generalInfo: null,
    statusLog: null,
    stats: null,
    activeTab: null,
    attachmentMetaEmail: null,
    attachmentMetaAudio: null
  }),

  watch: {
    alertId: {
      immediate: true,
      handler() {
        // Setting argument updateAlertListAlso to false to avoid
        // unnecessary/confusing updates of alert list when alert is selected
        // from list
        this.update(false);
      }
    }
  },

  methods: {
    update(updateAlertListAlso) {
      this.generalInfo = null;
      this.statusLog = null;
      this.attachmentMetaAudio = null;
      this.attachmentMetaEmail = null;
      const dataParams = { Statuslogg: true, Statistikk: true };
      apiAlert.getAlertDetails(this.alertId, dataParams, i18n).then(res => {
        this.generalInfo = res.Varsling;
        this.statusLog = res.StatusLogg.reverse();
        this.stats = res.Statistikk;
        const audioFileId = res.Varsling.Tale?.AudioFileId;
        if (audioFileId) {
          this.getAudioFileMeta(audioFileId);
        }
        const emailFiles = res.Varsling.Epost?.Attachments;
        if (emailFiles && emailFiles.length) {
          this.getEmailFilesMeta(emailFiles);
        }
      });
      if (updateAlertListAlso) {
        store.dispatch("updateAlertList");
      }
    },

    getAudioFileMeta(audioFileId) {
      this.attachmentMetaAudio = null;
      apiFile.getFileMetadata(audioFileId).then(audioMeta => {
        this.attachmentMetaAudio = audioMeta.Files[0];
      });
    },
    getEmailFilesMeta(attachmentData) {
      this.attachmentMetaEmail = null;
      let promises = attachmentData.map(data => {
        return apiFile.getFileMetadata(data.FileId);
      });

      Promise.all(promises).then(results => {
        let allMetaData = [];
        results.forEach(result => {
          allMetaData = allMetaData.concat(result.Files);
        });
        this.attachmentMetaEmail = allMetaData;
      });
    },

    cancelAlert() {
      apiAlert.cancelAlert(this.alertId).then(() => {
        this.update(true);
        store.commit("setAppSnackbar", this.$t("alertCanceled"));
      });
    }
  }
};
</script>

<style scoped>
.action-btn {
  min-width: 200px !important;
}
</style>
