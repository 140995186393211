<template>
  <div v-if="receivers" class="mt-2">
    <v-container>
      <v-row class="float-right">
        <UpdateBtn
          v-on:click="update()"
          class="mr-2 mt-2"
          :block="$vuetify.breakpoint.xs"
          data-testid="updateAlertReceiversList"
        />
        <LoadingSpinner v-if="exporting" id="exporting" />
        <ExportBtn
          :text="$t('exportAll')"
          v-on:click="exportLog"
          v-if="!exporting"
          :anonymize-option="true"
          :icon="true"
          class="mr-2 mt-2"
          v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
          :block="$vuetify.breakpoint.xs"
          data-testid="exportAlertReceiversList"
        />
        <v-btn
          @click="alertSelectedContactsAgain"
          outlined
          class="mt-2 mb-6"
          v-bind:class="{ success: receivesTbl.length }"
          :block="$vuetify.breakpoint.xs"
          data-testid="againAlertReceiversList"
        >
          {{ $tc("alertContactsAgain", receivesTbl.length) }}
        </v-btn>
      </v-row>
      <v-row class="mt-12">
        <v-text-field
          v-model.trim="search"
          :label="$t('search')"
        ></v-text-field>
      </v-row>
    </v-container>
    <v-data-table
      show-select
      item-key="Id"
      v-model="receivesTbl"
      :loading="loading"
      :headers="headers"
      :items="receivers"
      class="elevation-1"
      :footer-props="tblFooterProps"
      :page.sync="pageNumber"
      :server-items-length="receiversTotal"
      v-on:update:options="optionsUpdate"
    >
      <template v-slot:item.Mottaker="{ item }">
        {{ item.Mottaker }}
        <span v-if="item.ErVikarForNavn">
          ({{ $t("deputyFor").toLowerCase() + " " + item.ErVikarForNavn }})
        </span>
      </template>
      <template v-slot:item.KontaktKanal="{ item }">
        <span v-if="item.KontaktKanal === 'Sms'">
          {{ $t("sms") }}
        </span>
        <span v-else-if="item.KontaktKanal === 'Epost'">
          {{ $t("email") }}
        </span>
        <span v-else-if="item.KontaktKanal === 'Tale'">
          {{ $t("voice") }}
        </span>
      </template>
      <template v-slot:item.Tidspunkt="{ item }">
        {{ item.Tidspunkt | dateFilter }}
      </template>
      <template v-slot:item.SvarTekst="{ item }">
        <span v-if="item.SvarTekst">
          <i>"{{ item.SvarTekst }}"</i>
        </span>
        <div v-if="item.Respons && item.SvarTekst">
          {{ $t("withComment") }}: <br />
          <i>"{{ item.Respons }}"</i>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogNonSelected" :max-width="dialogWidth">
      <UserError v-on:ok="dialogNonSelected = false">
        <template v-slot:text>
          {{ $t("selContactsFirst") }}
        </template>
      </UserError>
    </v-dialog>
  </div>
</template>

<script>
import dateFilter from "../../filters/dateFilter";
import apiAlert from "../../api/v24/api.alert";
import LoadingSpinner from "../misc/LoadingSpinner";
import UpdateBtn from "../misc/UpdateBtn";
import config from "@/config/config";
import debounce from "@/util/debounce";
import Export from "@/util/Export";
import ExportBtn from "@/components/misc/ExportBtn";
import store from "@/store";
import ContactSource from "@/util/ContactSource";
import Contact from "@/util/Contact";
import PhoneNumber from "@/util/PhoneNumber";
import router from "@/router";
import UserError from "@/components/misc/UserError";
import anonymize from "@/util/anonymize";
import alertReceiverStatus from "@/util/alertReceiverStatus";
import i18n from "@/i18n";

export default {
  name: "AlertReceiverList",
  components: { UserError, ExportBtn, UpdateBtn, LoadingSpinner },
  props: {
    alertId: {
      required: true,
      type: String
    },
    alertName: {
      required: true,
      type: String
    },
    filterResponseCode: {
      required: false,
      type: String,
      default: ""
    }
  },
  watch: {
    alertId: {
      immediate: true,
      handler() {
        this.pageNumber = 1; // Resets pagination
        this.update();
      }
    },
    filterResponseCode: {
      handler() {
        this.pageNumber = 1;
        this.update();
      }
    },
    search: debounce.registerInput(function(newVal) {
      this.pageNumber = 1;
      this.searchReceivers(newVal);
    }, 500)
  },
  data() {
    return {
      dateFilter: dateFilter,
      loading: false,
      receivers: null,
      alertStatus: "",
      alertIsSimulated: false,
      search: "",
      pageNumber: 1,
      numberOfRows: config.defaultTblRowsPerPage,
      sortField: "",
      sortDesc: false,
      receiversTotal: 0,
      exporting: false,
      tblFooterProps: {
        itemsPerPageOptions: config.fixedTblItemsPerPageOptions
      },
      receivesTbl: [],
      dialogNonSelected: false,
      dialogWidth: config.dialogWith.medium
    };
  },
  computed: {
    alertAgainBtnColor() {
      if (this.receivesTbl.length) {
        return;
      }
    },
    headers() {
      let headers = [
        { text: this.$t("receiver"), value: "Mottaker" },
        { text: this.$t("contactData"), value: "Kontakt" },
        { text: this.$t("channel"), value: "KontaktKanal" },
        { text: this.$t("status"), value: "Status" },
        { text: this.$t("response"), value: "SvarTekst" },
        { text: this.$t("otherInfo"), value: "Kommentar" },
        { text: this.$t("lastUpdated"), value: "Tidspunkt", hide: "lgAndDown" }
      ];
      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide]);
    }
  },
  created() {
    this.update();
  },
  methods: {
    update() {
      if (this.search) {
        this.searchReceivers(this.search);
        return;
      }
      this.loading = true;
      apiAlert
        .getAlertDetails(
          this.alertId,
          { Mottakere: true, ResponsKode: this.filterResponseCode },
          i18n,
          this.pageNumber,
          this.numberOfRows,
          this.sortBy,
          this.sortDesc
        )
        .then(res => {
          this.setReceivers(res);
          this.setAlertStatus(res);
        })
        .finally(() => (this.loading = false));
    },
    optionsUpdate(options) {
      this.pageNumber = options.page;
      this.numberOfRows = options.itemsPerPage;
      this.sortBy = options.sortBy.length ? options.sortBy[0] : "";
      this.sortDesc = options.sortDesc.length ? options.sortDesc[0] : false;
      if (this.sortBy === "Mottaker") {
        this.sortBy = "Navn";
      } else if (this.sortBy === "Kontakt") {
        this.sortBy = "Telefon";
      } else if (this.sortBy === "SvarTekst") {
        this.sortBy = "Tast";
      } else if (this.sortBy === "KontaktKanal") {
        this.sortBy = "Kanal";
      }
      this.update();
    },
    searchReceivers(searchStr) {
      this.loading = true;
      apiAlert
        .searchAlertReceivers(
          this.alertId,
          searchStr,
          this.pageNumber,
          this.numberOfRows,
          this.sortBy,
          this.sortDesc,
          this.filterResponseCode
        )
        .then(res => this.setReceivers(res))
        .finally(() => (this.loading = false));
    },
    setReceivers(alertData) {
      this.receiversTotal = alertData.AntallKontakter;
      this.receivers = alertData.Mottakere;
    },
    setAlertStatus(alertData) {
      this.alertStatus = alertData.Varsling.VarslingStatus;
      this.alertIsSimulated = alertData.Varsling.Simulering;
    },
    exportLog(userChoices) {
      this.exporting = true;
      const headers = this.headers.map(header => {
        return header.text;
      });
      // "Comment" (API field "Respons") is merge to one cell in view, but in
      // the export it's better with a dedicated column
      headers.splice(5, 0, this.$t("comment"));

      const separator = config.getLocaleProperties().csvSeparator;
      const params = {
        Adresser: false,
        Mottakere: true,
        Statuslogg: false,
        Endringslogg: false,
        Statistikk: false,
        Saab: false
      };
      const i18nPerson = this.$t("person");
      const i18nContactdata = this.$t("contactData");
      apiAlert
        .getAlertDetails(this.alertId, params, i18n, 1, 9999999)
        .then(res => {
          let allReceivers = res.Mottakere;
          let data = [];
          allReceivers.forEach(receiver => {
            const row = [
              receiver.Mottaker,
              receiver.Kontakt,
              receiver.KontaktKanal,
              receiver.Status,
              receiver.SvarTekst,
              receiver.Respons,
              receiver.Kommentar,
              receiver.Tidspunkt
            ];
            data.push(row);
          });
          if (userChoices.anon) {
            const options = [
              { anonIdx: 0, prefix: i18nPerson },
              { anonIdx: 1, prefix: i18nContactdata }
            ];
            data = anonymize.anonymizeFieldsInDataset(data, options);
          }
          const exporter = new Export(this.alertName, data);
          exporter.setHeaders(headers);
          if (userChoices.useExcel) {
            exporter.exportAsExcel();
          } else {
            exporter.exportAsCsvFile(separator);
          }
          this.exporting = false;
        });
    },
    alertSelectedContactsAgain() {
      if (!this.receivesTbl.length) {
        this.dialogNonSelected = true;
        return;
      }
      store.commit("clearAlertReceivers");
      this.receivesTbl.forEach(receiver => {
        const contact = new Contact(receiver.Mottaker);
        const contactType = receiver.KontaktType;
        if (contactType === "Mobil") {
          contact.setMobile1(receiver.Kontakt);
        } else if (contactType === "Fast") {
          contact.setLandline1(receiver.Kontakt);
        } else if (contactType === "Epost") {
          contact.setEmail(receiver.Kontakt);
        }
        const cs = new ContactSource();
        cs.setAlertLog();
        contact.setSource(cs);
        store.commit("addAlertReceiver", contact.getAsApiObject());
      });
      router.push("/alert");
    }
  }
};

const getPhoneNumProps = number => {
  const pn = new PhoneNumber(number);
  return pn.getPhoneNumProperties();
};
</script>
<style scoped>
#exporting {
  float: right;
}
.xsBlockFix {
  width: 100%;
  margin-right: 0 !important;
}
</style>
