<template>
  <div>
    <v-container v-if="accumulatedResponses">
      <v-card>
        <v-card-title>
          {{ $t("overview") }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="float-right">
              <UpdateBtn v-on:click="update()" class="mr-2" />
            </v-row>
          </v-container>
          <v-layout align-center justify-center>
            <PieChart
              v-if="pieData"
              :data="pieData"
              class="mt-6"
              style="max-width: 300px"
            />
          </v-layout>
          <br />
          <v-layout align-center justify-center>
            <v-simple-table dense style="max-width: 300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("responseOption") }}
                    </th>
                    <th class="text-left">
                      {{ $t("amount") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ar in accumulatedResponses">
                    <td>{{ ar.OptionText }}</td>
                    <td style="text-align: right">
                      {{ ar.Amount }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-layout>
        </v-card-text>
      </v-card>

      <v-card class="mt-6" v-if="accumulatedResponses">
        <v-card-title>
          {{ $t("receivers") }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="responseFilter"
            v-if="accumulatedResponses"
            :label="$t('chooseResponseOption')"
            outlined
            :items="accumulatedResponses"
            item-text="OptionText"
            item-value="OptionText"
            return-object
          />
          <AlertReceiverList
            v-if="responseFilter && responseFilter.OptionCode"
            :alert-name="alertName"
            :alert-id="alertId"
            :filter-response-code="responseFilter.OptionCode"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import PieChart from "@/components/misc/PieChart";
import debounce from "@/util/debounce";
import UpdateBtn from "@/components/misc/UpdateBtn";
import AlertReceiverList from "@/components/alertLog/AlertReceiversList";
import apiAlert from "@/api/v24/api.alert";

export default {
  name: "AlertResponseOverview",
  components: { AlertReceiverList, UpdateBtn, PieChart },

  props: {
    alertId: {
      required: true,
      type: String
    },
    alertName: {
      required: true,
      type: String
    }
  },

  watch: {
    alertId: {
      immediate: true,
      handler() {
        this.update();
      }
    }
  },

  computed: {
    pieData() {
      if (!this.accumulatedResponses) {
        return null;
      }
      const data = [];
      const labels = [];
      // Graph will pick the x first colors. We don't need more then 9 colors,
      // since there is never more then 9 (8 in most cases) response options
      const colors = [
        "rgb(82,187,187)",
        "rgb(187,122,82)",
        "rgb(150,145,145)",
        "rgb(225,150,150)",
        "rgb(90,252,156)",
        "rgb(252,212,51)",
        "rgb(155,51,252)",
        "rgb(159,152,152)",
        "rgb(248,163,91)"
      ];
      this.accumulatedResponses.forEach((acRes, index) => {
        data.push(acRes.Amount);
        labels.push(acRes.OptionText);
      });
      return {
        datasets: [
          {
            data: data,
            backgroundColor: colors
          }
        ],
        labels: labels
      };
    },
    headers() {
      return [
        { text: this.$t("receiver"), value: "Mottaker" },
        { text: this.$t("contactData"), value: "Kontakt" },
        { text: this.$t("status"), value: "Status" },
        { text: this.$t("otherInfo"), value: "Kommentar" },
        { text: this.$t("lastUpdated"), value: "Tidspunkt" }
      ];
    }
  },

  data: () => ({
    accumulatedResponses: null,
    responseFilter: null,
    receivesTbl: [],
    loading: false,
    search: ""
  }),

  methods: {
    update() {
      const dataParams = { Statistikk: true };
      this.accumulatedResponses = null;
      apiAlert.getAlertDetails(this.alertId, dataParams).then(res => {
        this.accumulatedResponses = res.Statistikk.Mottakere.Respons.map(
          response => {
            let returnObj = {
              OptionText: response.StatusTekst,
              OptionCode: response.StatusKode,
              Amount: response.Antall
            };
            if (!returnObj.OptionCode) {
              returnObj.OptionText = this.$t("noResponse");
              returnObj.OptionCode = "-1";
            }
            return returnObj;
          }
        );
      });
    }
  }
};
</script>
