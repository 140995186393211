<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" xs="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ $t("alerts") }}</th>
                <th>{{ $t("receivers") }}</th>
                <th>{{ $t("sms") }}</th>
                <th>{{ $t("email") }}</th>
                <th>{{ $t("voice") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ alertStatsSum.Varslinger | numberFilter }}</td>
                <td>{{ alertStatsSum.Mottakere | numberFilter }}</td>
                <td>{{ alertStatsSum.SMS | numberFilter }}</td>
                <td>{{ alertStatsSum.Epost | numberFilter }}</td>
                <td>{{ alertStatsSum.Tale | numberFilter }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-12 mb-4">
      <div id="pie">
        <PieChart v-if="alertStatsSum" :data="pieData" />
      </div>
    </v-row>
  </div>
</template>

<script>
import PieChart from "@/components/misc/PieChart";
import config from "@/config/config";
import numberFilter from "@/filters/numberFilter";

export default {
  name: "AlertStatsSummarized",
  components: { PieChart },
  props: {
    alertStatsSum: {
      type: Object, // As returned from API
      required: true
    }
  },

  computed: {
    pieData() {
      return {
        labels: [this.$t("sms"), this.$t("email"), this.$t("voice")],
        datasets: [
          {
            data: [
              this.alertStatsSum.SMS,
              this.alertStatsSum.Epost,
              this.alertStatsSum.Tale
            ],
            backgroundColor: [
              config.chartColors.orange,
              config.chartColors.yellow,
              config.chartColors.blue
            ]
          }
        ]
      };
    }
  },

  data: () => ({
    pieOptions: {},
    numberFilter: numberFilter
  })
};
</script>

<style scoped>
#pie {
  max-width: 300px;
}
</style>
