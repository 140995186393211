/**
 * The V24 API support custom metadata/comments on an alert. This meta is
 * available in
 * the log and can be displayed there. This util genereates/parse strings to
 * use in this API field.
 */
export default class AlertMeta {
  constructor() {
    this.meta = [];
    this.key = {
      groupUsed: "UsedGroup"
    };
  }
  setGroupUsed(groupUsed) {
    this.meta.push(`${this.key.groupUsed}:${groupUsed}`);
  }

  getGroupsUsed(apiLogArray) {
    const groupUsed = apiLogArray.filter(comment =>
      comment.startsWith(this.key.groupUsed)
    );
    return groupUsed.map(group => group.split(":")[1].trim());
  }

  getMeta() {
    return this.meta;
  }
}

const getByKey = (metaData, key) => {
  return metaData.filter(meta => meta.startsWith(key + ":"));
};
