const anonymize = {

  /**
   * @name anonymizeFieldsInDataset
   *
   * @desc
   * Replaces given fields in 2 dimentional array with anon. value.
   *
   * @param {Array} options Array with object of type
   *
   * {anonIdx: <Int>, "prefix": "custom prefix, may be empty"}
   *
   * If in data is
   * [
   *  ["Mr. Blobby", 123],
   *  ["Mr. Bean, 321]
   * ]
   *
   * and options are
   *
   * [{anonIdx: 0, prefix: "Person"]
   *
   * result will be
   *
   * [
   *  ["Person 1", 123],
   *  ["Person 2", 321]
   * ]
   *
   */
  anonymizeFieldsInDataset(data, options) {
    const result = [];
    data.forEach((row, idx) => {
      options.forEach(option => {
        row[option.anonIdx] = option.prefix + idx;
      });
      result.push(row);
    });
    return result;
  },
}

export default anonymize;