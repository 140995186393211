<template>
  <v-row align="center" justify="center">
    <div id="pie">
      <PieChart :data="pieDataReceivers" />
    </div>
  </v-row>
</template>

<script>
import PieChart from "../misc/PieChart";
import i18n from "@/i18n";
import config from "@/config/config";
// In use even though IDE might say otherwise
import numberFilter from "../../filters/numberFilter";
import alertReceiverStatus from "@/util/alertReceiverStatus";

export default {
  name: "AlertStatsPie",
  components: { PieChart },
  props: {
    alertStats: {
      required: true,
      type: Object
    },
    alertStatus: {
      required: true,
      type: String
    },
    alertSimMode: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      pieDataReceivers: null
    };
  },

  computed: {
    statusCodeMap() {
      return {
        B: {
          text: alertReceiverStatus.translate(
            this.alertStatus,
            this.alertSimMode,
            "Bekreftet",
            i18n
          ),
          color: config.chartColors.strongGreen
        },
        M: {
          text: alertReceiverStatus.translate(
              this.alertStatus,
              this.alertSimMode,
              "Mottatt",
              i18n
          ),
          color: config.chartColors.green
        },
        S: {
          text: alertReceiverStatus.translate(
              this.alertStatus,
              this.alertSimMode,
              "Sendt",
              i18n
          ),
          color: config.chartColors.yellow
        },
        Y: {
          text: alertReceiverStatus.translate(
              this.alertStatus,
              this.alertSimMode,
              "Avvist",
              i18n
          ),
          color: config.chartColors.red
        },
        I: {
          text: alertReceiverStatus.translate(
              this.alertStatus,
              this.alertSimMode,
              "Ikke sendt",
              i18n
          ),
          color: config.chartColors.grey
        }
      };
    }
  },

  created() {
    if (this.alertStats.Mottakere.Antall) {
      this.pieDataReceivers = this.createPieData();
    }
  },

  methods: {
    createPieData() {
      let data = [];
      let labels = [];
      let backgroundColor = [];

      this.alertStats.Mottakere.Status.forEach(status => {
        const statusCode = status.StatusKode;
        if (statusCode === "D") {
          return;
        }
        data.push(status.Antall);
        labels.push(
          this.$options.filters.numberFilter(status.Antall) +
            " " +
            this.getStatusLabel(status)
        );
        backgroundColor.push(this.getStatusColor(status.StatusKode));
      });

      return {
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor
          }
        ],
        labels: labels
      };
    },

    getStatusLabel(status) {
      const statusCode = status.StatusKode;
      if (this.statusCodeMap[statusCode]) {
        return this.statusCodeMap[statusCode].text.toLowerCase();
      }
      return status.StatusTekst;
    },

    getStatusColor(statusCode) {
      if (this.statusCodeMap[statusCode]) {
        return this.statusCodeMap[statusCode].color;
      }
      return config.chartColors.white;
    }
  }
};
</script>

<style scoped>
#pie {
  max-width: 300px;
}
</style>
