import Vue from "vue";
import numberUtil from "../util/numberUtil";

const numberFilter = input => {
  if (!input && input !== 0) {
    return "";
  }
  // Do nothing if this is not a number
  if (!numberUtil.isNumber(input)) {
    return input;
  }
  let output = numberUtil.periodToComma(input);
  return numberUtil.addThousandSperators(output, " ");
};

Vue.filter("numberFilter", numberFilter);
export default numberFilter();
