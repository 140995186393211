<template>
  <v-timeline dense>
    <v-timeline-item
      v-for="status in statusLog"
      :key="status.Id"
      small
      color="grey"
    >
      <strong>
        {{ status.Tidspunkt | dateFilter }}
      </strong>
      <span id="status-txt">
        {{ parseStatusText(status.Tekst) }}
      </span>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import dateFilter from "../../filters/dateFilter";

export default {
  name: "AlertTimeline",
  props: {
    statusLog: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    dateFiler: dateFilter
  }),
  methods: {
    parseStatusText(statusTxt) {
      const status = statusTxt.replace("Status: ", "");
      if (status === "Planlagt") {
        return this.$t("alertStatusRegistered");
      } else if (status === "Aktiv") {
        return this.$t("alertStatusStarted");
      } else if (status === "Stoppet") {
        return this.$t("alertStatusStopped");
      } else if (status === "Utført") {
        return this.$t("alertStatusCompleted");
      } else {
        return status;
      }
    },
  }
};
</script>