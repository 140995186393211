<template>
  <div>
    <v-container>
      <v-row class="float-right">
        <ExportBtn v-on:click="exportData" />
      </v-row>
      <v-row>
        <v-text-field
          :label="$t('search')"
          v-model="search"
          data-testid="searchAlertStatsTable"
        />
      </v-row>
    </v-container>
    <v-data-table
      :items="alertStats.Stats"
      :headers="tblHeaders"
      :search="search"
      :custom-filter="filterList"
      :footer-props="tblFooterProps"
    >
      <template v-slot:item.VarslingDato="{ item }">
        {{ item.VarslingDato | dateFilter }}
      </template>
      <template v-slot:item.VarslingNavn="{ item }">
        {{ item.VarslingNavn }}
      </template>
      <template v-slot:item.Mottaker="{ item }">
        {{ item.Mottaker.Antall | numberFilter }}
      </template>
      <template v-slot:item.SMS="{ item }">
        {{ item.SMS.Antall | numberFilter }}
      </template>
      <template v-slot:item.Epost="{ item }">
        {{ item.Epost.Antall | numberFilter }}
      </template>
      <template v-slot:item.Tale="{ item }">
        {{ item.Tale.Antall | numberFilter }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-end">
          <v-btn
            icon
            outlined
            @click="showAlert(item)"
            data-testid="eyeAlertStatsTable"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-alert type="info" outlined dense>
      <p>
        {{ $t("statsInfo1") }}
      </p>
      <p>
        {{ $t("statsInfo2") }}
      </p>
    </v-alert>

    <v-dialog
      v-model="alertDetailsDialog"
      :max-width="dialogWidth"
      v-if="selectedAlert"
    >
      <AlertDetails
        :alert-id="selectedAlert.VarslingId"
        :show-card-actions="true"
        v-on:close="alertDetailsDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import AlertDetails from "@/components/alertLog/AlertDetails";
import config from "@/config/config";
import ExportBtn from "@/components/misc/ExportBtn";
import Export from "@/util/Export";
import dateFilter from "@/filters/dateFilter";
import search from "@/util/search";

export default {
  name: "AlertStatsTable",
  components: { ExportBtn, AlertDetails },
  props: {
    alertStats: {
      type: Object, // As returned from API
      required: true
    },
    fromMonth: {
      type: String,
      required: true
    },
    toMonth: {
      type: String,
      required: true
    }
  },

  computed: {
    tblHeaders() {
      return [
        {
          text: this.$t("time"),
          value: "VarslingDato"
        },
        {
          text: this.$t("name"),
          value: "VarslingNavn"
        },
        {
          text: this.$t("receivers"),
          value: "Mottaker"
        },
        {
          text: this.$t("smsMessage"),
          value: "SMS",
          sort: (a, b) => {
            return a.Antall > b.Antall ? 1 : -1;
          }
        },
        {
          text: this.$t("email"),
          value: "Epost",
          sort: (a, b) => {
            return a.Antall > b.Antall ? 1 : -1;
          }
        },
        {
          text: this.$t("voice"),
          value: "Tale",
          sort: (a, b) => {
            return a.Antall > b.Antall ? 1 : -1;
          }
        },
        {
          value: "actions",
          sortable: false
        }
      ];
    }
  },

  data: () => ({
    search: "",
    alertDetailsDialog: false,
    selectedAlert: null,
    dialogWidth: config.dialogWith.xLarge,
    dateFilter: dateFilter,
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    }
  }),

  methods: {
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    showAlert(alert) {
      this.selectedAlert = alert;
      this.alertDetailsDialog = true;
    },
    exportData(options) {
      const data = [];
      this.alertStats.Stats.forEach(stat => {
        let row = [
          stat.VarslingDato,
          stat.VarslingNavn,
          stat.Mottaker.Antall,
          stat.SMS.Antall,
          stat.SMS.Leveringsgrad,
          stat.Epost.Antall,
          stat.Epost.Leveringsgrad,
          stat.Tale.Antall,
          stat.Tale.Leveringsgrad
        ];
        data.push(row);
      });
      let monthInfo = "";
      if (this.fromMonth === this.toMonth) {
        monthInfo = this.fromMonth;
      } else {
        monthInfo = this.fromMonth + "_" + this.toMonth;
      }
      const filename = this.$t("stats") + "_" + monthInfo;
      const exporter = new Export(filename, data);
      exporter.setHeaders([
        this.$t("time"),
        this.$t("alertName"),
        this.$t("receivers"),
        this.$t("sms"),
        this.$t("sms") + " %",
        this.$t("email"),
        this.$t("email") + " %",
        this.$t("voice"),
        this.$t("voice") + " %"
      ]);
      if (options.useExcel) {
        exporter.exportAsExcel();
      } else {
        const separator = config.getLocaleProperties().csvSeparator;
        exporter.exportAsCsvFile(separator);
      }
    }
  }
};
</script>
